import styled from "styled-components"

export const ResultsImageStyles = styled.section`
position: relative;

.container {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 80px 30px 80px 30px;
  background: #F6D134;

  img {
      width: 100%;
  }
}

@media (min-width: 769px) {
  .container {
    padding: 80px 112px 80px 112px;
  }
}

@media (min-width: 1480px) {
  padding: 0 var(--borderSpacing) 0 var(--borderSpacing);
  .container {
    padding: 80px 112px 80px 112px;
  }
}

@media (min-width: 1680px) {
  .container {
    max-width: 1380px;
  }
}
`