import React from 'react';
import { ResultsImageStyles } from './ResultsImageStyles';

export const ResultsImage = ({image}) => {
  return (
    <ResultsImageStyles>
        <div className="container">
            <img src={image?.file?.url} alt={image?.description || null} />
        </div>
    </ResultsImageStyles>
  );
};