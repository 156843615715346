import * as React from "react";
import { RichTextRenderer } from "../RichTextRenderer/RichTextRenderer";
import { MainTextStyles } from "./MainTextStyles";

export const MainText = ({ content }) => {
  return (
    <MainTextStyles>
        {content && content.raw && (
          <div className="container">
              <RichTextRenderer
                richTextJson={content.raw}
                references={content.references || []}
              />
          </div>
        )}
    </MainTextStyles>
  )
}