import React from "react";
import useAllCampaigns from "../hooks/use-all-campaigns";
import useAllClients from '../hooks/use-all-clients';
import styled from "styled-components";
import { ClientSlider } from "../components/ClientsSlider/ClientSlider";
import { MainText } from "../components/MainText/MainText";
import { ResultsImage } from "../components/ResultsImage/ResultsImage";
import { RichTextCampaignBanner } from "../components/RichTextCampaignsBanner/RichTextCampaignBanner";
import { StaticImageBanner } from "../components/StaticImageBanner/StaticImageBanner";
import { TileSlider } from "../components/TileSlider/TileSlider";
import { transformTileContent } from '../utils/transform-data-functions';


const CaseStudyTemplateStyles = styled.div`
  .case-study-slider {
    .section {
      border-bottom: 0px;
    }
  }
`;

const CaseStudytemplate = (caseStudies) => {
  const {
    title,
    introductionParagraph,
    url,
    videoUrl,
    bannerImage,
    companyLogos,
    resultsImage,
    mainText,
  } = caseStudies

  const clients = useAllClients();
  const campaigns = transformTileContent(useAllCampaigns());

  return (
        <CaseStudyTemplateStyles>
          <StaticImageBanner image={bannerImage} title={title} video={videoUrl} />
          <RichTextCampaignBanner title={title} introPara={introductionParagraph} logos={companyLogos} hasDot={true} />
          <ResultsImage image={resultsImage} />
          <MainText content={mainText}/>
          <TileSlider className="case-study-slider" heading="Campaigns" intro="Our amazing team deliver incredible results across a variety of specialist services." tiles={campaigns} ctaText="View All" ctaLink="/campaigns" />
          <ClientSlider heading="Clients." logos={clients} />
        </CaseStudyTemplateStyles>
  )
}

export default CaseStudytemplate