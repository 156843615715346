import React from "react"
import { graphql } from "gatsby"
import CaseStudyTemplate from "../../templates/case-study-template"
import Seo from "../../components/SEO"
import Layout from "../../components/Layout"

const CaseStudy = ({ data: { contentfulCaseStudy } }) => {
  return (
    <>
      <Seo title={contentfulCaseStudy.title} />
      <Layout>
        <CaseStudyTemplate {...contentfulCaseStudy} />
      </Layout>
    </>
  )
}

export const data = graphql`
  query caseStudyQuery($id: String) {
    contentfulCaseStudy(id: { eq: $id }) {
      title
      url
      introductionParagraph {
        raw
      }
      videoUrl
      bannerImage {
        gatsbyImageData
        file {
          url
        }
        description
      }
      companyLogos {
        file {
          url
        }
        description
      }
      resultsImage {
        file {
          url
        }
        description
      }
      mainText {
        raw
        references {
            ... on ContentfulAsset {
              contentful_id
              title
              gatsbyImageData(layout: CONSTRAINED, quality: 80, formats: [WEBP, AUTO], placeholder: BLURRED)
              description
            }
        }
      }
    }
  }
`

export default CaseStudy
