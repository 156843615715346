import styled from "styled-components";

export const MainTextStyles = styled.section`
    position: relative;

    .container {
        height: 100%;
        display: block;
        /* align-items: flex-end;
        justify-content: flex-start; */
        padding: 110px 30px 80px 30px;
        background-color: #efecea;
    }

    @media (min-width: 769px) {
        .container {
          padding: 110px 112px 80px 112px;
        }
    }

    @media (min-width: 1480px) {
        padding: 0 var(--borderSpacing) 0 var(--borderSpacing);
        .container {
          padding: 110px 112px 80px 112px;
        }
    }

    @media (min-width: 768px) {
      flex-direction: row;
    }

    @media (min-width: 1680px) {
      .container {
        max-width: 1380px;
      }
    }

    h1 {
      margin-top: 0;
      font-size: 85px;
      line-height: 81px;
      font-weight: 700;
      letter-spacing: -2px;
      display: inline-block;
    
      @media (max-width: 1024px) {
        font-size: 48px;
        line-height: 47px;
      }
      @media (max-width: 620px) {
        font-size: 32px;
        line-height: 33px;
      }
    }

    h2 {
        font-size: 29px;
        line-height: 38px;
        font-weight: 500;
        letter-spacing: -0.8px;
        display: inline-block;
    }

    p {
      display: inline-block;
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      color: var(--textBlack);
      margin-right: 2%;
      width: 48%;

      @media (max-width: 980px) {
        margin-right: 0;
        width: 100%;
      }
    }
`;
