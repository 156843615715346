import * as React from "react"
import { RichTextRenderer } from "../RichTextRenderer/RichTextRenderer";
import { RichTextCampaignBannerStyles } from "./RichTextCampaignBannerStyles"

export const RichTextCampaignBanner = ({ title, introPara, subPara, logos, hasDot=true }) => {
  return (
    <RichTextCampaignBannerStyles>
      {title && (
        <div className="container">
          <div className="banner__content">
            <div className="leftPanel">
            <h1>
              {title}
              {hasDot && <span style={{ color: "var(--bodyColor)" }}>.</span>}
            </h1>
            {introPara && (
                <RichTextRenderer
                  richTextJson={introPara.raw}
                  references={introPara.references || []}
                />
              )}
            </div>
            <div className="rightPanel">
              {logos && <div className="logo-wrapper">
                {logos.map((logo, index) => (
                <img key={index} src={logo?.file?.url} alt={logo.description} />
              ))}</div>}
              {subPara && <div className="sub-para"><RichTextRenderer richTextJson={subPara.raw} /></div>}
            </div>
          </div>
        </div>
      )}
    </RichTextCampaignBannerStyles>
  )
}