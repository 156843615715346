import styled from "styled-components"

export const RichTextCampaignBannerStyles = styled.section`
  position: relative;

  .container {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 110px 30px 80px 30px;
    background-color: #efecea;
  }

  @media (min-width: 769px) {
    .container {
      padding: 110px 112px 80px 112px;
    }
  }

  @media (min-width: 1480px) {
    padding: 0 var(--borderSpacing) 0 var(--borderSpacing);
    .container {
      padding: 110px 112px 80px 112px;
    }
  }

  @media (min-width: 1680px) {
    .container {
      max-width: 1380px;
    }
  }

  .banner__image,
  .gradient {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  .gradient {
    background: radial-gradient(
      at bottom left,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }

  .banner__content {
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;
    justify-content: space-between;
    gap: 0px;
    position: relative;
    z-index: 2;
    width: 100%;

    .leftPanel, .rightPanel {
      display: flex;
      flex-grow: 1;
      position: relative;
      flex-direction: column;
      justify-content: space-between;
      .logo-wrapper {
        align-self: flex-start;
        max-width: 300px;
        img {
          width:100%;
        }
        img:first-child {
          margin-bottom: 5px;
        }
        @media (max-width: 1024px) {
            max-width: 200px;
        }

      }
      .sub-para {
          margin-top: auto; /* Pushes the sub-nav to the bottom */
      }
    }

    .rightPanel {
      align-items: flex-end;

      @media (min-width: 768px) {
        min-width: 294px;
      }
    }

    .leftPanel {
      align-items: flex-start;
      max-width: 884px;
    }

    @media (min-width: 1024px) {
      flex-direction: row;
      gap: 102px;;
    }

    h1 {
      margin-top: 0;
      font-size: 72px;
      line-height: 78px;
      font-weight: 700;
      letter-spacing: -2px;
      display: inline-block;
    
      @media (max-width: 1024px) {
        font-size: 48px;
        line-height: 47px;
        padding-top: 23px;
      }
      @media (max-width: 620px) {
        font-size: 32px;
        line-height: 33px;
      }
    }

    h2 {
        font-size: 29px;
        line-height: 38px;
        font-weight: 500;
        letter-spacing: -0.8px;
        display: inline-block;
    }

    p {
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      color: var(--textBlack);
    }
  }
`
